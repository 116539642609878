<script setup lang="ts">
import { VList } from "vuetify/components/VList";

interface Props {
  variant?: "flat" | "elevated" | "tonal" | "outlined" | "text" | "plain"; // previously `outlined` as prop
  // dense: boolean; // This prop is no longer available
  // expand: boolean; // This prop is no longer available
  minWidth?: string | number;
}

const props = withDefaults(defineProps<Props>(), {
  variant: undefined,
  minWidth: undefined,
});
</script>

<template>
  <VList v-bind="props">
    <slot />
  </VList>
</template>
